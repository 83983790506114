import { ChatFlowsEnum, DiscussionSteps, LanguageCodes } from "@limbic/types"

export type StateValueType = "boolean" | "string" | "number"

export enum EditorType {
  ACTION = "action",
  INELIGIBLE_USER = "ineligibleUser",
  CHAT_FLOW = "chatFlow",
  CONDITION = "condition",
  ADVANCED_CONDITION = "advancedCondition",
  QUESTION = "question"
}

export enum PromptType {
  InlinePicker = "inlinePicker",
  InlinePickerMultiSelect = "inlinePickerMultiSelect",
  PhoneNumber = "phoneNumber",
  Name = "name",
  Text = "text",
  Email = "email",
  Message = "message",
  Checkbox = "checkbox",
  Date = "date"
}

export interface IPromptSettings {
  checkboxOptions: {
    body: string
    initialValue: boolean
  }[]
  options: string[]
  optionsToSelectIndividually: string[]
  isUndoable?: boolean
  dataPointsName?: string
  trackResponse?: boolean
  setPeople?: boolean
  forceValue?: boolean
  crisisDetection?: boolean
  textPromptWithInlineOption?: boolean
  textPromptPlaceholder?: string
  textPromptForceValue?: boolean
  inputDateFormat?: string
  displayDateFormat?: string
  forceMobile?: boolean
  forceLandline?: boolean
  supportedCountries?: string[]
  phonePromptPlaceholder?: string
}

export interface IQuestionEditorValidity {
  hasMessages: boolean
  hasQuestionName: boolean
  hasOptions: boolean
  inputDateFormatValid: boolean
  displayDateFormatValid: boolean
}

export interface IQuestionEditorState {
  promptType: PromptType
  currentMessage: string
  messages: string[]
  questionName: string
  promptSettings: IPromptSettings
}

export interface IConditionEditorState {
  inputs: string[]
  ifStatement: {
    input: string
    action: string // TODO: Needs predefined values
  }
  thenBlock: {
    nextStep: string
  }
  elseBlock: {
    nextStep: string
  }
}

export enum Actions {
  TRACK = "track",
  SET_PEOPLE = "setPeople",
  SET_STATE = "setState",
  NEXT_DIALOGUE = "nextDialogue",
  SET_IAPT = "setIAPT",
  SET_LANGUAGE = "setLanguage",
  UPDATE_REFERRAL = "updateReferral",
  ADD_CLINICAL_NOTES = "addClinicalNotes",
  SET_RISK_LEVEL = "setRiskLevel",
  SUBMIT_REFERRAL = "submitReferral"
}

export interface IActionEditorState {
  action: Actions
  actionStateKey: string
  actionStateValueType: StateValueType
  actionStateValue: string
  nextDialogue: DiscussionSteps
  actionUpdateReferralWithKeys: string[]
  actionAddClinicalNotes: string[]
  actionAddClinicalNotesShouldUpdateReferral: boolean
  actionSetRiskLevel?: "MODERATE" | "HIGH"
  actionSetRiskLevelReason?: string
  actionSetRiskLevelIsCrisis?: boolean
  setIAPTid: string
  setIAPTName: string
  actionLanguageToSet: LanguageCodes | "browserLanguage" | "defaultLanguage"
}

export const chatFlowsMap: Record<ChatFlowsEnum, string> = {
  checkPostCodeFromAddressLookup: "Check user postcode and ask address",
  checkAlcoholConsumption: "Check alcohol consumption",
  askLongTermMedicalConditions: "Ask user's long term medical conditions and how they manage them",
  collectPhoneNumber: "Collect phone number and permissions",
  collectEmail: "Collect email",
  askCovidDetails: "Ask covid status and covid date",
  collectSubstances: "Ask about medication, substances and recreational drugs use",
  askCurrentlySeeingAMentalHealthProfessional:
    "Ask if user is currently seeing a mental health professional",
  askHadPriorMentalHealthTreatments: "Ask if user had prior mental health treatments",
  collectPreferredCorrespondence: "Ask user their preferred method of correspondence",
  collectEthnicity: "Ask user's ethnicity",
  collectNationality: "Ask user's nationality",
  collectGender: "Ask user's gender and if gender is same as one assigned at birth",
  collectSexuality: "Ask user's sexuality",
  collectLanguage: "Ask user's primary language and if interpreter is required",
  collectDisability: "Ask user's disability/disabilities",
  collectNHSNumber: "Ask user's NHS number",
  collectMainIssue: "Ask the main problem that the user is looking to refer for (main issue)",
  collectADHD: "Ask if user has an ADHD diagnosis",
  collectASD: "Ask if user has an ASD diagnosis",
  collectGoalForTherapy: "Ask the user's goal from therapy",
  collectReligion: "Ask the user's religion",
  collectDateOfBirth: "Ask the user's date of birth",
  collectName: "Ask user's name and preferred name",
  collectFeedback:
    "Ask user if the bot was helpful and additional feedback (improvement suggestions)",
  serviceSearch: "Check user's eligibility using their postcode or their GPs postcode",
  spineSearch: "Check user's eligibility using the NHS official database",
  submitReferral: "Submit referral to service",
  checkCrisisDetection: "Crisis detection signpost",
  getPermissions: "Display privacy policy and terms and conditions and get user's consent",
  assessmentAndTreatments:
    "Assessment questionnaires (PHQ9, GAD7, WASAS, Social Phobia, IRQ-A, ITQ, OCL, PCL5, PDSS, SHAI18, Specific Phobia)",
  assessmentCustomisableADSM: "Assessment ADSM Customisable",
  goodbyeRecap: "Goodbye Recap: Summarise outcomes from the referral and next steps",
  bookAppointment: "Book appointment with service",
  collectSMI: "Collect Severe Mental Illness (SMI)",
  questionnaires: "Questionnaires",
  collectUSAddress: "Collect US Address"
}

export type Action = {
  name: Actions
  value?: string | string[]
  data?: string[]
}

export type NodeValidation = {
  id: string
  isValid: boolean
  type: string | undefined
}

export interface IFlowValidation {
  nodes: NodeValidation[]
  hasFlowStart: boolean
  hasFlowEnd: boolean
  needsSubmitReferral: boolean
  someNodesInvalid: boolean
}
